import React from "react";
import {
  SEO,
  MarkdownContent,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 0 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "71470604-328a-4bae-8e0e-c56c01a8b8dc",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={0} />
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"left"}>
            <Grid.Column className={""} style={{ padding: 16 }} width={6}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "395add28-1d55-4f96-8de1-420c2d1c1f4e",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={624}
                  businessType={"merchant"}
                  locationId={661}
                  subheader={"Catering Form"}
                  showLabels={true}
                  emailSubject={"Catering Form"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "Full name",
                      displayLabel: "Name",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "text",
                      placeholder: "Email address",
                      displayLabel: "Email",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "phone",
                      required: true,
                      inputType: "text",
                      placeholder: "Phone number",
                      displayLabel: "Phone",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label: "address",
                      required: true,
                      inputType: "textarea",
                      placeholder: "Full address",
                      displayLabel: "Address",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "guests",
                      required: true,
                      inputType: "text",
                      placeholder: "Number of guests",
                      displayLabel: "Guests",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "date",
                      required: true,
                      inputType: "text",
                      placeholder: "Event date",
                      displayLabel: "Date",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "time",
                      required: true,
                      inputType: "text",
                      placeholder: "Event time",
                      displayLabel: "Time",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label: "details",
                      required: false,
                      inputType: "textarea",
                      placeholder: "Additional details",
                      displayLabel: "Details",
                      isFieldContactMapped: false,
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 25657 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
